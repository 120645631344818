import { combineReducers } from 'redux'
import session from './session'
import brokerConfig from './brokerConfigReducer'
import liveData from './liveDataReducer'

const rootReducer = combineReducers({
  session,
  brokerConfig,
  liveData,
})

export default rootReducer
