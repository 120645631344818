export const SitesRedirect = (props) => {
  props.history.push('inventory-sites')
  return null
}

export const PlayersRedirect = (props) => {
  props.history.push('inventory-players')
  return null
}

export const DevicesRedirect = (props) => {
  props.history.push('inventory-devices')
  return null
}

export const AppsRedirect = (props) => {
  props.history.push('inventory-apps')
  return null
}