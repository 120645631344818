import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { deleteSession } from '../../actions/session'

const LogoutView = class extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(deleteSession())
  }

  render() {
    return null
  }
}

LogoutView.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(LogoutView)
