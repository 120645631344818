import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Login } from '@csdm-frontend/csdm-idp-frontend'
import { createSession } from '../../actions/session'
import wallpaper from '../../assets/images/background.jpg'
import maintenance from '../../assets/images/maintenance.png'

const PASSWORD_RECOVERY_URL = `${window.location.origin}/recovery/token/`
const RESET2FA_URL = `${window.location.origin}/reset2fa/token/`

class LoginView extends Component {
  onLoginCallback = () => {
    this.props.dispatch(createSession())
  }

  render() {
    if (window._env_.MAINTENANCE === true) {
      return (
        <div className="flex one" style={{justifyContent: 'center', alignItems: 'center'}}>
          <img alt='maintenance' src={maintenance} style={{width: '50%'}}></img>
        </div>
      )
    } else {
      return (
        <Login
          background={wallpaper}
          PASSWORD_RECOVERY_URL={PASSWORD_RECOVERY_URL}
          RESET2FA_URL={RESET2FA_URL}
          callback={this.onLoginCallback}
          extraHeader='Please use your Chrome browser for an optimal experience'
          language="gb"
        />
      )
    }
  }
}

LoginView.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default connect()(LoginView)
