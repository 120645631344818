import { getToken, logout , validateToken, getTokenInformation } from '@csdm-frontend/csdm-idp-frontend'
import { setRoles } from '../routes'
import {
  LIVE_DATA_SELECTION_CLEARED,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  DELETE_SESSION,
} from '../constants/actionTypes'

const CMDB_TOKEN_NAME = 'IDP_TOKEN_MAB'


// MAB-CMDB is checking his own token. Please set it!
const setMabCmdbToken = () => {
  const token = getToken()
  localStorage.setItem(CMDB_TOKEN_NAME, token)
}

export const requestSession = () => ({ type: CREATE_SESSION_REQUEST })
export const sessionCreated = session => ({ type: CREATE_SESSION_SUCCESS, session })
export const clearLiveDataSelection = () => ({ type: LIVE_DATA_SELECTION_CLEARED })
export const sessionDelete = () => ({ type: DELETE_SESSION })

export const deleteSession = (removeToken=true) => dispatch => {
  if (removeToken) {
    logout()
    // Remove MAB-CMDB token as well
    localStorage.removeItem(CMDB_TOKEN_NAME)
    sessionStorage.clear()
  }

  dispatch(clearLiveDataSelection())
  dispatch(sessionDelete())
}

export const createSession = () => async (dispatch) => {
  sessionStorage.clear()
  setMabCmdbToken()
  const newSession = {
    user: getTokenInformation(),
    token: getToken(),
  }
  setRoles(newSession.user.roles)
  dispatch(sessionCreated(newSession))
}

export const validateSession = () => async (dispatch) => {
  dispatch(requestSession())
  validateToken()
    .then(() => {
      dispatch(createSession())
    })
    .catch(() => {
      dispatch(deleteSession(false))
    })
}
