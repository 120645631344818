import {
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  DELETE_SESSION
} from '../constants/actionTypes'

const defaultState = {
  isAuthenticated: false,
  loading: true,
  token: null,
  user: null,
}

const session = (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        ...action.session,
      }
    case DELETE_SESSION:
      return {
        ...defaultState,
        loading: false,
      }
    default:
      return state
  }
}

export default session
