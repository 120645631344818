import {
  LIVE_DATA_SELECTION_UPDATE,
  LIVE_DATA_SELECTION_CLEARED,
} from '../constants/actionTypes'

const initialState = {
  countryCode: null,
  siteList: [],
  siteIdx: undefined,
  nrsIdx: undefined,
  ncbIdx: undefined,
  interval: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIVE_DATA_SELECTION_UPDATE: {
      return { ...state, ...action.payload };
    }
    case LIVE_DATA_SELECTION_CLEARED: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
