export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const DELETE_SESSION = 'DELETE_SESSION'
export const BROKER_CONFIG_SET_SITE_AND_MAC = 'BROKER_CONFIG_SET_SITE_AND_MAC';
export const BROKER_CONFIG_REQUEST = 'BROKER_CONFIG_REQUEST';
export const BROKER_CONFIG_FETCHED = 'BROKER_CONFIG_FETCHED';
export const BROKER_CONFIG_ERROR = 'BROKER_CONFIG_ERROR';
export const BROKER_CONFIG_FETCHING_ERROR = 'BROKER_CONFIG_FETCHING_ERROR';
export const BROKER_CONFIG_UPDATED = 'BROKER_CONFIG_UPDATED';
export const BROKER_CONFIG_SWITCH_IS_FDDP = 'BROKER_CONFIG_SWITCH_IS_FDDP';
export const BROKER_CONFIG_REQUEST_UPDATE = 'BROKER_CONFIG_REQUEST_UPDATE';
export const BROKER_CONFIG_UPDATE_SUCCESS = 'BROKER_CONFIG_UPDATE_SUCCESS';
export const LIVE_DATA_SELECTION_UPDATE = 'LIVE_DATA_SELECTION_UPDATE';
export const LIVE_DATA_SELECTION_CLEARED = 'LIVE_DATA_SELECTION_CLEARED';







