import {
  BROKER_CONFIG_SET_SITE_AND_MAC,
  BROKER_CONFIG_REQUEST,
  BROKER_CONFIG_FETCHED,
  BROKER_CONFIG_ERROR,
  BROKER_CONFIG_FETCHING_ERROR,
  BROKER_CONFIG_UPDATED,
  BROKER_CONFIG_SWITCH_IS_FDDP,
  BROKER_CONFIG_REQUEST_UPDATE,
  BROKER_CONFIG_UPDATE_SUCCESS,
} from '../constants/actionTypes'

const initialState = {
  siteCode: null,
  macAddress: null,
  data: null,
  isFetching: false,
  isSuccess: false,
  isFddp: true,
  isFetchingError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BROKER_CONFIG_SET_SITE_AND_MAC: {
      const { siteCode, macAddress } = action.payload
      const newState = {
        ...state,
        siteCode,
        macAddress,
        isSuccess: false,
        isFetchingError: false
      };
      return newState;
    }

    case BROKER_CONFIG_REQUEST: {
      const newState = {
        ...state,
        data: null,
        isFetching: true,
        isSuccess: false,
        isFetchingError: false,
      };
      return newState;
    }
    case BROKER_CONFIG_FETCHED: {
      const isFddp = action.payload.fddpClientConfig.length > 0
      const newState = {
        ...state,
        data: action.payload,
        isFetching: false,
        isSuccess: false,
        isFetchingError: false,
        isFddp,
      };
      return newState;
    }
    case BROKER_CONFIG_ERROR: {
      const newState = {
        ...state,
        isFetching: false,
        isSuccess: false,
        isFetchingError: false,
      };
      return newState;
    }
    case BROKER_CONFIG_FETCHING_ERROR: {
      const newState = {
        ...state,
        isFetching: false,
        isSuccess: false,
        isFetchingError: true,
      };
      return newState;
    }
    case BROKER_CONFIG_UPDATED: {
      const newState = {
        ...state,
        data: { ...state.data, ...action.payload },
        isSuccess: false,
        isFetchingError: false,
      };
      return newState;
    }
    case BROKER_CONFIG_SWITCH_IS_FDDP: {
      const newState = {
        ...state,
        isFddp: !state.isFddp,
        isSuccess: false,
        isFetchingError: false,
      };
      return newState;
    }
    case BROKER_CONFIG_REQUEST_UPDATE: {
      const newState = {
        ...state,
        isFetching: true,
        isSuccess: false,
        isFetchingError: false,
      };
      return newState;
    }
    case BROKER_CONFIG_UPDATE_SUCCESS: {
      const newState = {
        ...state,
        isFetching: false,
        isSuccess: true,
        isFetchingError: false,
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};
