import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const AuthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
  return(
  <Route
    {...rest}
    render={(props) => {
      if (cProps.isAuthenticated) {
        return <C {...props} {...cProps} />
      }
      return (<Redirect
        to={`/login?redirect=${props.location.pathname}${props.location
          .search}`}
      />)
    }}
  />
)}

export default AuthenticatedRoute

AuthenticatedRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  props: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    // userHasAuthenticated: PropTypes.func.isRequired,
    // setUserInfoAfterAuthentication: PropTypes.func.isRequired,
  }).isRequired,
}

AuthenticatedRoute.defaultProps = {
  location: {
    pathname: '',
    search: '',
  },
}
