import React from 'react'
import { Recovery } from '@csdm-frontend/csdm-idp-frontend'
import wallpaper from '../../assets/images/background.jpg'

const LOGIN_URL = `${window.location.origin}/login`

export const RecoveryView = (props) => (
    <Recovery
      language="gb"
      background={wallpaper}
      token={props.match.params.index}
      LOGIN_URL={LOGIN_URL}
    />
  )

