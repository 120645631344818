import React, { Component } from 'react'
import { Layout, Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  ROUTE_SUPPORT_SITE_INDEX,
  ROUTE_SUPPORT_TICKET_CREATE,
  ROUTE_INVENTORY_APPS,
  ROUTE_INVENTORY_DEVICES,
  ROUTE_INVENTORY_ITEMS,
  ROUTE_INVENTORY_PLAYERS,
  ROUTE_INVENTORY_SITES,
  ROUTE_INVENTORY_BULK_UPLOAD,
  // ROUTE_SITE_FACILITIES,
  WEBPLAYER_SCREEN_LAYOUTS_READ,
  ROUTE_FUEL_TYPES,
  ROUTE_CIS_COMPONENTS,
  ROUTE_CIS_CAMPAIGNS,
  ROUTE_CIS_CREATIVES,
  getLink,
  routeAllowed
} from '../routes'
import logo from '../assets/images/oop_logo.png'
import './menuStyle.css'

const { SubMenu } = Menu
const rootSubmenuKeys = ['1', '2', '3', '4', '5', '6', '7', '8']
const { Sider } = Layout

export default class SiderMenu extends Component {
  constructor() {
    super()
    this.state = {
      openKeys: [],
    }
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.collapsed !== this.props.collapsed) {
      if (nextProps.collapsed) {
        this.setState({
          openKeys: [],
        })
      }
      return false
    }
    return true
  }

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }

  // showOnlyInDev = () => {
  //   const env = window.location.origin
  //   if (env === 'http://localhost:3000') {
  //     return true
  //   }
  //   else if (env === 'https://shell-portal-frontend-dot-csdm-shell-dev.appspot.com') {
  //     return true
  //   } else if (env === 'https://shell-portal-dtest.csdm.cloud') {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  render() {
    // const showOnlyInDev = this.showOnlyInDev()
    const styleClassMenu = this.props.collapsed ? 'shell-sider-menu-collapsed' : 'shell-sider-menu'
    return (
      <Sider
        id="SHELL-sider-menu"
        className={styleClassMenu}
        style={{ background: '#fff'}}
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed) => { this.props.collapseMenu(collapsed) }}
      >
        <div style={{ width: '100%' }}>
          <Link to='/' className="MenuItem subnav-button">
            <img src={logo} alt="oop-logo" className="logo" />
          </Link>
        </div>
        <Menu
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          mode="inline"
        >
          {(routeAllowed(ROUTE_CIS_CAMPAIGNS.path)) && (
            <SubMenu
              key="1"
              title={
                <span><Icon type="video-camera" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Multichannel</span>
                </span>
                }
            >
              {(routeAllowed(ROUTE_CIS_CAMPAIGNS.path)) &&
                <SubMenu
                  key="1-1"
                  title={<span className="dsp_submenu_text_span">CIS webplayer</span>}
                >
                  <Menu.Item key="1-1-1" >
                   <Link to={getLink(ROUTE_CIS_CAMPAIGNS)} className="MenuItem subnav-button">Manage Loops</Link>
                  </Menu.Item>
                  <Menu.Item key="1-1-2" >
                    <Link to={getLink(ROUTE_CIS_CREATIVES)} className="MenuItem subnav-button">Manage Slots</Link>
                  </Menu.Item>
                  {(routeAllowed(ROUTE_CIS_COMPONENTS.path)) && <Menu.Item key="1-1-3" >
                    <Link to={getLink(ROUTE_CIS_COMPONENTS)} className="MenuItem subnav-button">Component settings</Link>
                  </Menu.Item>}
                </SubMenu>
              }
            </SubMenu>
          )}

          {(routeAllowed(ROUTE_SUPPORT_SITE_INDEX.path)) && (
            <SubMenu
              key="3"
              title={
                <span><Icon type="dashboard" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Support</span>
                </span>
                }
            >
              <Menu.Item key='3-1'>
                <Link to={getLink(ROUTE_SUPPORT_SITE_INDEX)} className="MenuItem subnav-button">Site overview</Link>
              </Menu.Item>
            </SubMenu>
          )}

          {/* {showOnlyInDev && */}
           {(routeAllowed(ROUTE_INVENTORY_PLAYERS.path)) && (
            <SubMenu
              key="4"
              title={
                <span><Icon type="database" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Inventory</span>
                </span>
                }
            >
              <Menu.Item key='4-1'>
                <Link to={getLink(ROUTE_INVENTORY_PLAYERS)} className="MenuItem subnav-button">Manage players</Link>
              </Menu.Item>
              <Menu.Item key="4-2" >
                <Link to={getLink(ROUTE_INVENTORY_SITES)} className="MenuItem subnav-button">Manage sites</Link>
              </Menu.Item>
              <Menu.Item key='4-3'>
                <Link to={getLink(ROUTE_INVENTORY_DEVICES)} className="MenuItem subnav-button">Manage devices</Link>
              </Menu.Item>
              <Menu.Item key='4-4'>
                <Link to={getLink(ROUTE_INVENTORY_APPS)} className="MenuItem subnav-button">Manage apps</Link>
              </Menu.Item>
              <Menu.Item key='4-5'>
                <Link to={getLink(ROUTE_INVENTORY_ITEMS)} className="MenuItem subnav-button">Configuration</Link>
              </Menu.Item>
              {(routeAllowed(ROUTE_INVENTORY_BULK_UPLOAD.path)) && <Menu.Item key='4-6'>
                <Link to={getLink(ROUTE_INVENTORY_BULK_UPLOAD)} className="MenuItem subnav-button">Bulk upload</Link>
              </Menu.Item>}
              {/* <Menu.Item key='4-5'>
                <Link to={getLink(ROUTE_SITE_FACILITIES)} className="MenuItem subnav-button">Site facilities</Link>
              </Menu.Item> */}
            </SubMenu>
            )
          }

          {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_READ.path)) && (
            <SubMenu
              key="5"
              title={
                <span>
                  <Icon type="play-circle-o" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Webplayer</span>
                </span>
                }
            >
              {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_READ.path)) &&
                <Menu.Item key='5-1'>
                  <Link to={getLink(WEBPLAYER_SCREEN_LAYOUTS_READ)} className="MenuItem subnav-button">Screen layouts</Link>
                </Menu.Item>
              }
            </SubMenu>
          )}

          {(routeAllowed(ROUTE_FUEL_TYPES.path)) && (
            <SubMenu
              key="7"
              title={
                <span>
                  <Icon type="setting" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Configuration</span>
                </span>
                }
            >
              <Menu.Item key='7-1'>
                <Link to={getLink(ROUTE_FUEL_TYPES)} className="MenuItem subnav-button">Fuel types</Link>
              </Menu.Item>
            </SubMenu>
          )}

          {(routeAllowed(ROUTE_SUPPORT_TICKET_CREATE.path)) && (
            <Menu.Item key="9">
              <Link to={getLink(ROUTE_SUPPORT_TICKET_CREATE)} className="MenuItem subnav-button">
                <span>
                  <Icon type="customer-service" className="dsp_icon_menu" />
                  <span className="dsp_submenu_text_span">Need more help?</span>
                </span>
              </Link>
            </Menu.Item>
          )}

        </Menu>
      </Sider>
    )
  }
}

SiderMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  collapseMenu: PropTypes.func.isRequired,
}
