import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import enUS from './utils/language/locale-provider/en_US'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { HttpLink  } from 'apollo-link-http'
import { ApolloLink, concat } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getToken } from '@csdm-frontend/csdm-idp-frontend'
import store from './store'
import App from './App'
import * as serviceWorker from './serviceWorker';

const FETCH_URI = window._env_.OOP_ENDPOINT;
const httpLink = new HttpLink({ uri: `${FETCH_URI}/graphql`});

const authMiddleware = new ApolloLink((operation, forward) => {
  // get the authentication token from the IDP
  const token = getToken()
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  }));

  return forward(operation);
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions : {
    mutate: {
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ConfigProvider>
  </Provider>
  , document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

