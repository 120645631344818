import React from 'react'

export default function NotFound() {
  return (
    <div className="NotFound">
      <div className="NotFound-404" >404</div>
      <h1 style={{ color: '#37A2DB' }}>Sorry, page not found!</h1>
    </div>
  )
}
