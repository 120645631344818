import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const UnauthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!cProps.isAuthenticated) {
          return <C {...props} {...cProps} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

export default UnauthenticatedRoute;

UnauthenticatedRoute.propTypes = {
  props: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    // userHasAuthenticated: PropTypes.func.isRequired,
    // setUserInfoAfterAuthentication: PropTypes.func.isRequired,
  }).isRequired,
};
